<div
  class="tw-flex tw-w-full tw-justify-end tw-gap-x-2"
  *transloco="let t; read: 'shared'"
  data-testid="modalActions"
>
  @if (shouldShowCancelButton_) {
    <app-button
      [backgroundColor]="'transparent'"
      [isDisabled]="!canModalBeClosed_"
      (click)="onCloseButtonClick()"
    >
      {{ areActionButtonsPresent() ? t('cancel') : (t('close') | titlecase) }}
    </app-button>
  }
  <ng-content />
</div>
